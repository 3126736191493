<template>
  <div>
    <b-modal
        id="modal-delete-user"
        ref="modal-delete-user"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        no-close-on-esc
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.deleteUser.title') }}</h3>
      </template>

      <template #default>
        <div class="delete-icon text-center">
          <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
          <div v-if="currentUserId === userUserId">
            <h3>{{ $t('modals.deleteUser.selfDelete') }}</h3>
          </div>
          <div class="mt-2 mb-4">{{ $t('modals.deleteUser.message') }}</div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="p-3"
            block
            @click="deleteUser"
        >
          {{ $t('modals.deleteUser.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DeleteTeamMember from '@/graphQlQueries/mutations/deleteTeamMember';
import DeleteGroupMember from '@/graphQlQueries/mutations/deleteGroupMember';

export default {
  name: 'ModalDeleteUser',
  props: ['userUserId', 'teamId', 'groupId', 'currentUserId'],
  mounted() {
    this.$refs['modal-delete-user'].show();
  },
  methods: {
    async deleteUser() {
      try {
        if (this.teamId) {
          await this.$apollo.mutate({
            mutation: DeleteTeamMember,
            variables: {
              obj: {
                userId: this.userUserId,
                teamId: this.teamId,
              },
            },
          });
        }
        if (this.groupId) {
          await this.$apollo.mutate({
            mutation: DeleteGroupMember,
            variables: {
              obj: {
                user_id: this.userUserId,
                group_id: this.groupId,
              },
            },
          });
        }
      } catch (ex) {
        console.error(ex);
      }
      this.$emit('deleteUser', this.userUserId);
    },
  },
};
</script>

<style scoped>

</style>
