import gql from 'graphql-tag';

export default gql`
mutation deleteGroupMember($obj: DeleteGroupMemberInput!) {
  deleteGroupMember(object: $obj) {
    group_id
    user_id
  }
}
`;
