var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modal-delete-user",
        attrs: {
          id: "modal-delete-user",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.deleteUser.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "delete-icon text-center" },
                  [
                    _c("b-icon", {
                      staticClass: "mt-5 mb-4",
                      attrs: {
                        icon: "exclamation-triangle-fill",
                        variant: "danger"
                      }
                    }),
                    _vm.currentUserId === _vm.userUserId
                      ? _c("div", [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("modals.deleteUser.selfDelete"))
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mt-2 mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("modals.deleteUser.message")))
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.deleteUser }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("modals.deleteUser.validate")) + " "
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }