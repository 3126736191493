import gql from 'graphql-tag';

export default gql`
mutation deleteTeamMember($obj: DeleteTeamMemberInput!) {
  deleteTeamMember(object: $obj) {
    team_id
    user_id
  }
}
`;
